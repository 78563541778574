<script setup lang="ts">
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { VReferralProgramStepProps } from 'web/src/modules/referral-program/components/ReferralProgramStep/VReferralProgramStep/types';
import { useVReferralProgramStep } from 'web/src/modules/referral-program/components/ReferralProgramStep/VReferralProgramStep/composables';

const props = defineProps<VReferralProgramStepProps>();

const {
  handleClick,
} = useVReferralProgramStep(props);
</script>

<template>
  <div v-auto-id="'VReferralProgramStep'"
    :class="{
      [$style['referral-program-step']]: true,
      [$style['referral-program-step--satisfied']]: satisfied,
      [$style['referral-program-step--link']]: !!to,
      [$style['referral-program-step--info']]: !!isInfo,
      [$style['referral-program-step--green']]: green,
      [$style['referral-program-step--satisfied-green']]: green && satisfied,
      [$style['referral-program-step--is-rounded']]: isRounded,
    }"
    @click="handleClick"
  >
    <div :class="$style['referral-program-step__left']">
      <div :class="$style['referral-program-step__icon']">
        <VIcon :name="satisfied ? IconName.CHECK_FILLED : IconName.FAKE_CHECKBOX" />
      </div>
      <div :class="$style['referral-program-step__label']">
        {{ label }}
      </div>
    </div>
    <div
      v-if="satisfied"
      :class="$style['referral-program-step__button']"
    >
      {{ $t('WEB2_REFFERAL_PROGRAM_POPUP_READY') }}
    </div>
    <VIcon
      v-else
      :class="$style['referral-program-step__arrow']"
      :name="IconName.EXPAND_RIGHT"
      :size="IconSize.SIZE_16"
    />
  </div>
</template>

<style lang="scss" module>
.referral-program-step {
  $self: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  padding: 0 14px 0 8px;
  background-color: var(--OpacityLayer1);

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:first-child:last-child {
    border-radius: 5px;
  }

  &__arrow {
    color: var(--TextDefault);
  }

  &__left {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 44px;
  }

  &__icon {
    display: flex;
    align-items: center;
    color: var(--Layer0);
  }

  &__label {
    @include medium\14\20\025;

    color: var(--TextDefault);
  }

  &__button {
    @include medium\11\16\-02\caps;

    padding: 2px 6px;
    color: var(--BrandDefault);
    background-color: var(--OpacityLayer1);
    border: none;
    border-radius: 4px;
  }

  &__fake-checkbox {
    background-color: var(--Layer0);
    border-radius: 50%;
  }

  &--green {
    background-color: var(--Layer1);

    #{$self}__label {
      color: var(--TextPrimary);
    }
    #{$self}__icon {
      color: var(--Layer0);
    }
  }

  &--satisfied {
    #{$self}__label {
      text-decoration: line-through;
    }

    #{$self}__icon {
      color: var(--BrandDefault);
    }

    &-green {
      background-color: var(--OpacityBrandDefault);

      #{$self}__label {
        color: var(--TextPrimary);
      }
      #{$self}__icon {
        color: var(--BrandDefault);
      }
      #{$self}__button {
        background-color: var(--OpacityBrandDefault);
      }
    }
  }

  &--info {
    background-color: var(--Layer1);
    #{$self}__label {
      color: var(--TextPrimary);
      text-decoration: none;
    }
    #{$self}__button,
    #{$self}__arrow {
      display: none;
    }
  }

  &--link {
    cursor: pointer;
  }

  &--is-rounded {
    border-radius: 5px;
  }
}
</style>
