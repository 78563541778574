import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { IconName, IconSize } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';

import type { VIconProps } from '@components/v-icon';

import type {
  VReferralProgramStepProps,
} from 'web/src/modules/referral-program/components/ReferralProgramStep/VReferralProgramStep/types';

export interface VReferralProgramStepComposable {
  iconProps: ComputedRef<VIconProps>;
  handleClick(): void;
}

export default function useVReferralProgramStep(props: VReferralProgramStepProps): VReferralProgramStepComposable {
  const router = useRouter();
  const analytics = useAnalytics();

  const iconProps = computed(() => ({
    size: IconSize.SIZE_20,
    name: props.satisfied ? IconName.CHECK_FILLED : IconName.FAKE_CHECKBOX,
  }));

  function handleClick() {
    if (props.to) {
      void router.push({ name: props.to });

      let steps = '';

      switch (props.to) {
        case RouteName.DEPOSITS:
          steps = 'deposit';
          break;
        case RouteName.SPORTLINE_TOP_EVENTS:
          steps = 'spin_bet';
          break;
        default:
          steps = 'verification';
          break;
      }
      analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
        referFriendProgram: {
          noAccessPage: {
            steps,
          },
        },
      });
    }
  }

  return {
    iconProps,
    handleClick,
  };
}
