import type { I18nFunctionReactive } from '@core/i18n';
import type { FormatMoneyFunc } from '@core/money';

import type { ReferralDataGetDataDocument } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import type { ReferralProgramBodyProps } from 'web/src/modules/referral-program/views/ReferralProgramPopup';
import getCompletedProgramRequirements
  from 'web/src/modules/referral-program/utils/referralProgramPopup/getCompletedProgramRequirements';
import getCurrentProgramRequirements
  from 'web/src/modules/referral-program/utils/referralProgramPopup/getCurrentProgramRequirements';
import getPlayerReward from 'web/src/modules/referral-program/utils/referralProgramPopup/getPlayerReward';
import getRequirementsRoute
  from 'web/src/modules/referral-program/utils/referralProgramPopup/getRequirementsRoute';
import getRequirementsTitle
  from 'web/src/modules/referral-program/utils/referralProgramPopup/getRequirementsTitle';
import getRewardTitle from 'web/src/modules/referral-program/utils/referralProgramPopup/getRewardTitle';

export default function getBodyProps(
  programReferral: Maybe<ReferralDataGetDataDocument>,
  $t: I18nFunctionReactive,
  formatMoney: FormatMoneyFunc,
  locale: string,
): ReferralProgramBodyProps {
  const completedProgramRequirements = getCompletedProgramRequirements(programReferral);
  const currentProgramRequirements = getCurrentProgramRequirements(programReferral);
  const playerReward = getPlayerReward(programReferral?.rewards ?? null, $t, formatMoney, locale);

  const completed = completedProgramRequirements.map((requirement) => ({
    label: getRequirementsTitle(requirement, $t, formatMoney),
  }));

  const current = currentProgramRequirements.map((requirement) => ({
    label: getRequirementsTitle(requirement, $t, formatMoney),
    to: getRequirementsRoute(requirement.type),
  }));

  const referralReward = programReferral?.referralDetails?.referralReward;

  return {
    completed,
    current,
    rewards: {
      referralReward: !referralReward,
      table: playerReward,
      rewardLabel: referralReward ? getRewardTitle(referralReward, $t, locale) : '',
      showTitle: true,
    },
  };
}
